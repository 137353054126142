.App {
  text-align: center;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.css-voqfbn-MuiTable-root tbody tr td {
  padding: 8px !important;
}
.css-11vq0v0-MuiTableCell-root.MuiTableCell-paddingCheckbox {
  padding: 8px !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
